import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Pricing from "../components/pricing"
import Logo from "../components/logo"

import lemonadeOffer from "../images/img/lemonade_offer_img.png"
import arrowDownred from "../images/img/arrow-down-red.svg"

import MainLayout from "../components/Layout/MainLayout"
import Button from "../components/button"

const scrollDown = () => {
  window.scrollTo({
    left: 0,
    top: 1050,
    behavior: "smooth"
  })
}

export class VideoPreviewPage extends React.Component {
  constructor(props) {
    super(props)
  }

  componentDidMount() {
    if (typeof window !== "undefined") {
      const API_URL = "https://europe-west1-mindworxacademy.cloudfunctions.net/geolocation"
      fetch(API_URL, { method: "GET" }).then(res => res.json())
        .then(json => {
          if (json.country === "US" && window.location.href.indexOf("us") == -1) {
            window.location.replace("https://mindworxacademy.com/videopreview-us" + document.location.search)
          } else if (json.country === "GB" && window.location.href.indexOf("uk") == -1) {
            window.location.replace("https://mindworxacademy.com/videopreview-uk" + document.location.search)
          }
        })
        .catch(_ => {
          //  reject(err)
        })
    }
  }

  render() {
    return (
      <MainLayout>
        <div className="videoPreview-wrapper">
          {/* <img
            alt=" lemonade Offer"
            className="videoPreview_lemonadeImg"
            src={lemonadeOffer}
            title=" lemonade Offer"
          /> */}
          <h1 className="videoPreview_title">
            Here’s your <br/> Masterclass Preview
          </h1>
          <div className="videoPreview-videoWrapper">
            <div className="videoPreview-videoContainer">
              <iframe
                className="videoPreview__videoIframe"
                title="video preview"
                allowFullScreen
                frameBorder="0"
                mozallowfullscreen=""
                src="https://player.vimeo.com/video/388968272"
                webkitallowfullscreen=""
              ></iframe>
            </div>
          </div>
          <div
            onClick={scrollDown}
            role="button"
            tabindex="0"
            onKeyDown={scrollDown}
          >
            <img
              className="videoPreview__img"
              alt="Red arrow down icon"
              src={arrowDownred}
              title="Arrow down icon"
            ></img>
          </div>
          <Pricing
            checkoutUrls={this.props.checkoutUrls}
          />
          <section style={{ maxWidth: "1350px", margin: "50px auto" }}>
            <div
              className="box_two_footer page_content"
              style={{ marginTop: "80px" }}
            >
              Are you a student, NGO or charity and would love to join — but have
              no budget for it? Request one of the MINDWORX Academy Scholarships <a className="styled"
                                                                                    href="mailto:info@mindworxacademy.com">here</a>
              . We make it a point to contribute to worthy causes. Let us know
              yours and we’ll get back to you.
            </div>
            <div
              className="box_two_footer page_content"
              style={{ marginTop: "80px" }}
            />
          </section>

          <footer className="ebooksFooter" style={{ marginTop: "40px" }}>
            <div>
              <h3 className="footerTitle">Any questions?</h3>
              <p className="footerContactUs">
                We’re here to help - contact us{" "}
                <a href="mailto:info@mindworxacademy.com" className="footerLink">
                  HERE
                </a>
              </p>
              <div className="footerLogoWrapper">
                <a href="/#" title="MINDWORX" className="logo">
                  <Logo style={{ width: "100%", height: "auto" }} type={"white"}/>
                </a>
              </div>
            </div>
          </footer>
        </div>
      </MainLayout>
    )
  }
}


const Page = (props) => {
  const {
    eur // Us pricing configuration
  } = useStaticQuery(query)
    .site
    .siteMetadata
    .pricingConfiguration

  // const { ebook, complete, video } = us

  return (
    <>
      <VideoPreviewPage checkoutUrls={eur}/>
    </>
  )
}

const query = graphql`
query EurVideoPreviewPage {
  site {
    siteMetadata {
      pricingConfiguration {
        eur {
          ebook {
            price
            oldPrice
            url
          }
          complete {
            price
            oldPrice
            url
          }
          video {
            price
            oldPrice
            url
          }
        }
      }
    }
  }
}
`


export default Page
